<template>
  <section>
    <pm-Breadcrumb :home="home" :model="items_bread" />
    <!-- <va-breadcrumbs>
        <va-breadcrumbs-item label="Educacao" to="/admin/educacao/segmentos" />
        <va-breadcrumbs-item>
          <span style="font-style: italic; color: #0785d3"
            >Aluno historico</span
          >
        </va-breadcrumbs-item>
    </va-breadcrumbs> -->
    <h2 class="titulo-links-escolha">Aluno Histórico</h2>

    <div class="pm-card">
      <pm-DataTable :value="item" dataKey="id" :rowHover="true" >
        <pm-Column field="tipoEvento" header=""></pm-Column>

        <pm-Column field="ano" header="ANO"></pm-Column>

        <pm-Column field="escola" header="ESCOLA"></pm-Column>

        <pm-Column field="segmento" header="SEGMENTO"></pm-Column>

        <pm-Column field="serie" header="PERÍODO"></pm-Column>

        <pm-Column field="turma_nome" header="TURMA"></pm-Column>

        <pm-Column field="entrou_turma_data" header="DATA ENTRADA TURMA"></pm-Column>

      </pm-DataTable>

      <div class="field col-12 md:col-2">
        <pm-Button label="Voltar"  class="p-button-danger mt-5" icon="pi pi-angle-left" iconPos="left" @click="voltar()" />
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import { Aluno } from "@/class/alunos.js";
import { SegmentoEscolar } from "@/class/segmentoEscolar.js";
import { SeriesEscolar } from "@/class/serie.js";
import { Escola } from "@/class/escolas.js"
import DataTables from "@/pages/admin/tables/data-tables/DataTables.vue";


export default defineComponent({
    props: {
        id: {},
        pg: {}
    },
    data() {
      return {
        home: { icon: "pi pi-home", to: "#" },
        items_bread: [
          { label: "Aluno Histórico" },
        ],
        item: [],
      };
    },
    methods: {
      voltar() {
        this.$router.push({ name: "alunosAll", params: { pg: this.pg } });
      },
      async historicoAluno() {
        try {
          let aluno = {
            "aluno_id": this.id
          };
          let data = await Aluno.historicoAluno(aluno);
          //percorre array para pegar todo o histórico do aluno
          for (let i = 0; i < data.data.length; i++) {
            let el = data.data[i];
            //let seg = await SegmentoEscolar.obtemUm(el.segmento_id);
            //let seri = await SeriesEscolar.obtemUm(el.serie_id);
            let esc = await Escola.obtemUm(el.escola_id);

            /*if(el.evento != null){
              let n = {
                aluno_id: el.aluno_id,
                ano: "",
                escola: "",
                segmento:"",
                serie: "",
                turma_nome: "",
                entrou_turma_data: "",
                tipoEvento: el.evento.tipoEvento,
              }
              this.item.push(n);
            }*/

            if(el.evento != null){
              if(el.evento.tipoEvento == "Transferencia de Turma"){
                let novo = {
                  aluno_id: el.aluno_id,
                  ano: el.ano,
                  escola: esc.data.nome,
                  segmento:el.segmento_nome,
                  serie: el.serie_nome,
                  turma_nome: el.turma_nome,
                  entrou_turma_data: el.entrou_turma_data,
                  tipoEvento: el.evento.tipoEvento,
                };
                this.item.push(novo);
              }else{
                let novo = {
                  aluno_id: el.aluno_id,
                  ano: "",
                  escola: "",
                  segmento: "",
                  serie: "",
                  turma_nome: "",
                  entrou_turma_data: el.entrou_turma_data,
                  tipoEvento: el.evento.tipoEvento,
                };
                this.item.push(novo);
              }
            }else{
              let novo = {
                aluno_id: el.aluno_id,
                ano: el.ano,
                escola: esc.data.nome,
                //segmento: seg.data.nome,
                segmento:el.segmento_nome,
                serie: el.serie_nome,
                //serie: seri.data.nome,
                turma_nome: el.turma_nome,
                entrou_turma_data: el.entrou_turma_data,
                tipoEvento: "ENTROU NA TURMA",
              };
              this.item.push(novo);
            }

          }
        }catch (e) {
          // console.log(e);
        }
      },
    },
    beforeMount() {
        if (this.id == null) {
            this.$router.push({ name: "alunosAll", params: { pg: this.pg } });
        }
        else {
            this.historicoAluno();
        }
    },
    components: { DataTables }
});
</script>

<style lang="scss" scoped>
.table-example--pagination {
  padding-top: 20px;
  text-align: center;
  text-align: -webkit-center;
}
</style>
